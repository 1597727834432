import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'

const Coupons = ({ coupons }) => {
  return (
    <>
      <Stack
        title={'Latest Crypto Coupons & Deals'}
        titleLink={'/crypto/coupons'}
      >
        {coupons.nodes.length && (
          <>
            <Main>
              <CardList
                isCoupon={true}
                nodes={coupons.nodes}
                limit={2}
                variant={['horizontal-md', 'horizontal']}
              />
            </Main>
            <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
              <CardList
                isCoupon={true}
                nodes={coupons.nodes}
                limit={1}
                skip={2}
                variant={['horizontal-md', 'vertical']}
              />
            </Sidebar>
          </>
        )}
      </Stack>
      <Divider space={5} />
    </>
  )
}

export default Coupons
