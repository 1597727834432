import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import HeroComponent from '../components/Hero/Hero'
import Exchanges from '../components/Exchanges'
import WalletReviews from '../components/WalletReviews'
import Coupons from '../components/Coupons'
import Comparisons from '../components/Comparisons'

const Posts = ({
                 data: { exchanges = {}, featuredPosts = {}, comparisons = {}, walletReviews = {}, coupons = {}, recentPosts = {} },
                 ...props
               }) => {
  const { pageContext: { services = {} } = {} } = props

  return (
    <Layout {...props}>
      <Seo title="Home" />
      <Divider />
      <Stack effectProps={{ effect: false }}>
        <CardList
          nodes={featuredPosts.nodes}
          limit={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'vertical-cover']}
          title="Find The Best Cryptocurrency Exchange In:"
          countryList={true}
          loading="eager"
          omitCategory
        />
      </Stack>
      <Divider space={2} />
      <Stack>
        <CardList
          nodes={featuredPosts.nodes}
          limit={6}
          skip={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'horizontal-aside']}
          loading="eager"
        />
      </Stack>
      <Divider />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent />
      </Hero>
      <Divider />
      {exchanges.nodes.length > 0 && <Exchanges exchanges={exchanges} />}
      {coupons.nodes.length > 0 && <Coupons coupons={coupons} />}
      {walletReviews.nodes.length > 0 && <WalletReviews walletReviews={walletReviews} />}
      {comparisons.nodes.length > 0 && <Comparisons comparisons={comparisons} />}
      <Divider space={5} />
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts
