import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'

const Exchanges = ({ exchanges }) => {
  return (
    <>
      <Stack
        title={'Latest Crypto Exchange Reviews'}
        titleLink={'/crypto/reviews'}
      >
        {exchanges.nodes.length && (
          <>
            <Main>
              <CardList
                nodes={exchanges.nodes}
                limit={2}
                variant={['horizontal-md', 'horizontal']}
              />
            </Main>
            <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
              <CardList
                nodes={exchanges.nodes}
                limit={1}
                skip={2}
                variant={['horizontal-md', 'vertical']}
              />
            </Sidebar>
          </>
        )}
      </Stack>
      <Divider space={5} />
    </>
  )
}

export default Exchanges
