import { graphql } from 'gatsby'
import PostsPage from '../containers/Posts'

export default PostsPage

export const pageQuery = graphql`
  query PostsPageQuery($paginatePostsPage: Boolean!, $skip: Int, $limit: Int) {
    featuredPosts: allBestCountryExchange(
      sort: { fields: [date], order: DESC }
      limit: 10
    ) {
      nodes {
        ...BestCountryExchangePreview
        ...BestCountryExchangeThumbnailFeatured
      }
    }

    recentPosts: allExchange(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ExchangePreview
        ...ExchangeThumbnailRegular
        ...ExchangeLogo
      }
    }

    exchanges: allReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      nodes {
        ...ReviewPreview
        ...ReviewThumbnailRegular
        ...ReviewLogo
      }
    },

    wallets: allWallet(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      nodes {
        ...WalletPreview
        ...WalletThumbnailRegular
        ...WalletLogo
      }
    },

    coupons: allCoupon(
      filter: {
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      nodes {
        ...CouponPreview
      }
    },

    comparisons: allComparison(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      nodes {
        ...ComparisonPreview
        ...ComparisonThumbnailRegular
      }
    },

    walletReviews: allWalletReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: 1000
    ) @skip(if: $paginatePostsPage) {
      nodes {
        ...WalletReviewPreview
        ...WalletReviewThumbnailRegular
        ...WalletReviewLogo
      }
    },

    paginatedPosts: allReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
      }
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) @include(if: $paginatePostsPage) {
      nodes {
        ...ReviewPreview
        ...ReviewThumbnailRegular
        ...ReviewLogo
      }
      ...ReviewPagination
    }
  }
`
