import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'

const Comparisons = ({ comparisons }) => {
  return (
    <>
      <Stack
        title={'Latest Crypto Exchange Guides'}
        titleLink={'/crypto/guides'}
      >
        {comparisons.nodes.length && (
          <>
            <Main>
              <CardList
                nodes={comparisons.nodes}
                limit={1}
                variant={['horizontal-md', 'horizontal']}
              />
              <Divider space={2} />
              <CardList
                nodes={comparisons.nodes}
                limit={2}
                skip={2}
                columns={[1, 2]}
                variant={['horizontal-md', 'horizontal-aside']}
                omitMedia
                omitCategory
              />
              <Divider space={2} />
              <CardList
                nodes={comparisons.nodes}
                limit={2}
                skip={4}
                columns={[1, 2]}
                variant={['horizontal-md', 'horizontal-aside']}
                mediaType="icon"
                omitCategory
              />
            </Main>
            <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
              <CardList
                nodes={comparisons.nodes}
                limit={1}
                skip={1}
                variant={['horizontal-md', 'vertical']}
              />
            </Sidebar>
          </>
        )}
      </Stack>
      <Divider space={5} />
    </>
  )
}

export default Comparisons
